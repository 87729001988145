import React from "react"
import { Header } from "../components/header"
import { Layout } from '../components/layout'


export const Page: React.FC<any> = ({ pageContext }) => {
  console.log(pageContext)
  return (
    <div>
      <Header  />
      <h1>{pageContext.title}</h1>
      <iframe width="100%" height="900" src={pageContext.file}></iframe>
    </div>
  )
}

export default Page
